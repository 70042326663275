var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "search-component",
      attrs: { "grid-list-md": "", fluid: "", "px-2": "", "py-1": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "panel",
                {
                  staticClass: "query-panel d-flex fill-height",
                  attrs: { "fill-height": "" },
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _vm.$APP == "foldseek"
                        ? [
                            _vm._v(
                              "\n                Input protein\n                "
                            ),
                            !_vm.$vuetify.breakpoint.smAndDown
                              ? [
                                  _vm._v(
                                    "\n                    structure (PDB/CIF) or sequence (FASTA)\n                "
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : [_vm._v("\n                Queries\n            ")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "toolbar-extra" },
                    [
                      _c("api-dialog", {
                        attrs: {
                          disabled: _vm.searchDisabled,
                          email: _vm.email,
                          mode: _vm.mode,
                          database: _vm.database,
                          taxfilter: _vm.taxFilter ? _vm.taxFilter.value : "",
                        },
                      }),
                      _vm._v(" "),
                      _vm.query.length > 0
                        ? _c(
                            "v-icon",
                            {
                              staticStyle: { "margin-right": "16px" },
                              attrs: { title: "Clear" },
                              on: {
                                click: function ($event) {
                                  _vm.query = ""
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$MDI.Delete))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { "open-delay": "300", top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(_vm._s(_vm.$MDI.HelpCircleOutline)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$STRINGS.QUERIES_HELP)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("query-textarea", {
                        attrs: { loading: _vm.accessionLoading },
                        model: {
                          value: _vm.query,
                          callback: function ($$v) {
                            _vm.query = $$v
                          },
                          expression: "query",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions input-buttons-panel" },
                        [
                          _c(
                            "div",
                            { staticClass: "input-buttons-left" },
                            [
                              _vm.$APP == "foldseek"
                                ? _c("load-acession-button", {
                                    attrs: {
                                      "preload-source": _vm.preloadSource,
                                      "preload-accession": _vm.preloadAccession,
                                    },
                                    on: {
                                      select: function ($event) {
                                        _vm.query = $event
                                      },
                                      loading: function ($event) {
                                        _vm.accessionLoading = $event
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("file-button", {
                                attrs: {
                                  id: "file",
                                  label: _vm.$STRINGS.UPLOAD_LABEL,
                                },
                                on: { upload: _vm.upload },
                              }),
                              _vm._v(" "),
                              _vm.$APP == "foldseek"
                                ? _c("PredictStructureButton", {
                                    attrs: { query: _vm.query },
                                    on: {
                                      predict: function ($event) {
                                        _vm.query = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.predictable,
                                      callback: function ($$v) {
                                        _vm.predictable = $$v
                                      },
                                      expression: "predictable",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("file-button", {
                                attrs: {
                                  id: "localFile",
                                  label: "Upload previous results",
                                },
                                on: { upload: _vm.uploadJSON },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "panel",
                {
                  attrs: {
                    collapsible: "",
                    collapsed: "",
                    "render-collapsed": "",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      !_vm.$vuetify.breakpoint.smAndDown
                        ? [_vm._v("\n                Databases\n            ")]
                        : [_vm._v("\n                DBs\n            ")],
                      _vm._v("\n            & search settings\n        "),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("databases", {
                        attrs: {
                          selected: _vm.database,
                          "all-databases": _vm.databases,
                          hideEmail: _vm.hideEmail,
                        },
                        on: {
                          "update:selected": function ($event) {
                            _vm.database = $event
                          },
                          "update:all-databases": function ($event) {
                            _vm.databases = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.mode,
                            callback: function ($$v) {
                              _vm.mode = $$v
                            },
                            expression: "mode",
                          },
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { "open-delay": "300", top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "label",
                                        _vm._g({}, on),
                                        [
                                          _vm._v("Mode "),
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  "margin-top": "-3px",
                                                },
                                                attrs: {
                                                  color: "#FFFFFFB3",
                                                  small: "",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$MDI.HelpCircleOutline
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.$STRINGS.MODE_HELP),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.$STRINGS.MODE_COUNT - 0, function (i) {
                            return _c("v-radio", {
                              key: i,
                              attrs: {
                                "hide-details": "",
                                value: _vm.$STRINGS["MODE_KEY_" + i],
                                label: _vm.$STRINGS["MODE_TITLE_" + i],
                              },
                            })
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("TaxonomyAutocomplete", {
                        model: {
                          value: _vm.taxFilter,
                          callback: function ($$v) {
                            _vm.taxFilter = $$v
                          },
                          expression: "taxFilter",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { "open-delay": "300", top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-checkbox",
                                    {
                                      model: {
                                        value: _vm.iterativeSearch,
                                        callback: function ($$v) {
                                          _vm.iterativeSearch = $$v
                                        },
                                        expression: "iterativeSearch",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "label" }, [
                                        _c(
                                          "label",
                                          _vm._g({}, on),
                                          [
                                            _vm._v(
                                              "\n                            Iterative search\n                            "
                                            ),
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticStyle: {
                                                    "margin-top": "-3px",
                                                  },
                                                  attrs: {
                                                    color: "#FFFFFFB3",
                                                    small: "",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$MDI.HelpCircleOutline
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "Improve sensitivity of search by performing an iterative search (--num-iterations 3)."
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.$ELECTRON && !_vm.hideEmail
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { "open-delay": "300", top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                label:
                                                  "Notification Email (Optional)",
                                                placeholder: "you@example.org",
                                              },
                                              model: {
                                                value: _vm.email,
                                                callback: function ($$v) {
                                                  _vm.email = $$v
                                                },
                                                expression: "email",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                968467921
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("Send an email when the job is done."),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c(
                "panel",
                [
                  _c("template", { slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "actions",
                        style: !_vm.$vuetify.breakpoint.xsOnly
                          ? "display:flex; align-items: center;"
                          : null,
                      },
                      [
                        _c(
                          "v-item-group",
                          { staticClass: "v-btn-toggle" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  block: false,
                                  "x-large": "",
                                  disabled: _vm.searchDisabled,
                                },
                                on: { click: _vm.search },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.$MDI.Magnify)),
                                ]),
                                _vm._v(" Search"),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isMultimer
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "secondary",
                                      block: false,
                                      "x-large": "",
                                    },
                                    on: { click: _vm.goToMultimer },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(_vm._s(_vm.$MDI.Multimer)),
                                    ]),
                                    _vm._v(" Go to Multimer"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            style: !_vm.$vuetify.breakpoint.xsOnly
                              ? "margin-left: 1em;"
                              : "margin-top: 1em;",
                          },
                          [
                            _c("span", [_c("strong", [_vm._v("Summary")])]),
                            _c("br"),
                            _vm._v("\n                Search "),
                            _vm.taxFilter
                              ? [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.taxFilter.text)),
                                  ]),
                                  _vm._v(" in\n                "),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.database.length == _vm.databases.length
                              ? [
                                  _c("strong", [_vm._v("all available")]),
                                  _vm._v(" databases\n                "),
                                ]
                              : [
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.database.length)),
                                  ]),
                                  _vm._v(" "),
                                  _vm.database.length == 1
                                    ? [
                                        _vm._v(
                                          "\n                        database\n                    "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                        databases\n                    "
                                        ),
                                      ],
                                  _vm._v(" "),
                                  _vm.database.length > 0
                                    ? [
                                        _vm._v(
                                          "\n                    (" +
                                            _vm._s(
                                              _vm.databases
                                                .filter(function (db) {
                                                  return _vm.database.includes(
                                                    db.path
                                                  )
                                                })
                                                .map(function (db) {
                                                  return db.name
                                                })
                                                .sort()
                                                .join(", ")
                                            ) +
                                            ")\n                    "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                            _vm._v(
                              " with " + _vm._s(_vm.$STRINGS.APP_NAME) + " in "
                            ),
                            _vm.iterativeSearch
                              ? [_vm._v("iterative")]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("strong", [_vm._v(_vm._s(_vm.modes[_vm.mode]))]),
                            _vm._v(" mode.\n                "),
                            _vm.errorMessage != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-alert v-alert--outlined warning--text mt-2",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.errorMessage)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("reference", { attrs: { reference: _vm.$STRINGS.CITATION } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }