var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticStyle: { overflow: "visible", height: "100%" },
          attrs: { fluid: "", "pa-2": "" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "flex-col" },
                [
                  _c(
                    "v-card",
                    { staticStyle: { height: "100%" } },
                    [
                      _c("v-card-title", [_vm._v("Summary")]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-simple-table",
                            {
                              staticClass: "settings auto-height-table",
                              staticStyle: { height: "100%" },
                              attrs: { id: "settings" },
                            },
                            [
                              _c("tbody", [
                                _vm.$LOCAL &&
                                _vm.statistics.hasOwnProperty("db")
                                  ? _c("tr", [
                                      _c("td", [_vm._v("Database")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { attrs: { id: "msa-database" } },
                                        [_vm._v(_vm._s(_vm.statistics.db))]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$LOCAL &&
                                _vm.statistics.hasOwnProperty("msaFile")
                                  ? _c("tr", [
                                      _c("td", [_vm._v("MSA file")]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { id: "msa-file" } }, [
                                        _vm._v(_vm._s(_vm.statistics.msaFile)),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.statistics.hasOwnProperty("msaLDDT")
                                  ? _c("tr", [
                                      _c("td", [_vm._v("MSA LDDT")]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { id: "msa-lddt" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statistics.msaLDDT.toFixed(3)
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.statistics.hasOwnProperty("cmdString")
                                  ? _c("tr", [
                                      _c("td", [_vm._v("Command")]),
                                      _vm._v(" "),
                                      _c("td", { attrs: { id: "msa-cmd" } }, [
                                        _vm._v(
                                          _vm._s(_vm.statistics.cmdString)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.tree
                ? _c(
                    "v-col",
                    { staticClass: "flex-col" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "fill-height",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticStyle: {
                                position: "absolute",
                                left: "0",
                                top: "0",
                                margin: "0",
                                padding: "16px",
                                "z-index": "1",
                              },
                            },
                            [_vm._v("Guide Tree")]
                          ),
                          _vm._v(" "),
                          _c("Tree", {
                            attrs: {
                              newick: _vm.tree,
                              order: _vm.entries.map(function (e) {
                                return e.name
                              }),
                              selection: _vm.structureViewerSelection.map(
                                function (i) {
                                  return _vm.entries[i].name
                                }
                              ),
                              reference: _vm.structureViewerReference,
                            },
                            on: {
                              newStructureSelection:
                                _vm.handleNewStructureViewerSelection,
                              newStructureReference:
                                _vm.handleNewStructureViewerReference,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "flex-col" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "fill-height",
                      staticStyle: { position: "relative" },
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticStyle: {
                            position: "absolute",
                            left: "0",
                            top: "0",
                            margin: "0",
                            padding: "16px",
                            "z-index": "1",
                          },
                        },
                        [_vm._v("Structure")]
                      ),
                      _vm._v(" "),
                      _vm.structureViewerSelection
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px",
                                height: "100%",
                                width: "100%",
                              },
                            },
                            [
                              _c("StructureViewerMSA", {
                                attrs: {
                                  entries: _vm.entries,
                                  selection: _vm.structureViewerSelection,
                                  reference: _vm.structureViewerReference,
                                  mask: _vm.mask,
                                },
                                on: {
                                  loadingChange:
                                    _vm.handleStructureLoadingChange,
                                  columnSelected: function ($event) {
                                    _vm.selectedColumn = $event
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c("v-card-text", [
                            _vm._v(
                              "\n                        No structures loaded.\n                    "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "minimap fill-height" },
            [
              _vm.cssGradients
                ? _c(
                    "v-row",
                    {
                      staticStyle: { "align-items": "center" },
                      attrs: { dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticStyle: {
                            "max-width": "fit-content",
                            "margin-right": "4px",
                            position: "relative",
                          },
                          attrs: { align: "center", "no-gutters": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "input-div-wrapper expansion-panel",
                                  class: {
                                    "is-expanded": _vm.settingsPanelOpen,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-div" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "input-label",
                                          attrs: {
                                            title:
                                              "Toggle between AA and 3Di alphabets",
                                          },
                                        },
                                        [_vm._v("Alphabet")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            dense: "",
                                            mandatory: "",
                                            color: "primary",
                                          },
                                          model: {
                                            value: _vm.alphabet,
                                            callback: function ($$v) {
                                              _vm.alphabet = $$v
                                            },
                                            expression: "alphabet",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                "x-small": "",
                                                value: "aa",
                                              },
                                            },
                                            [_vm._v("AA")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                "x-small": "",
                                                value: "ss",
                                              },
                                            },
                                            [_vm._v("3Di")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-div" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "input-label",
                                          attrs: {
                                            title:
                                              "Hide columns with percentage of gaps above this cutoff",
                                          },
                                        },
                                        [_vm._v("Gaps")]
                                      ),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticStyle: {
                                          "max-width": "80px",
                                          "max-height": "20px",
                                        },
                                        attrs: {
                                          label: "0.0",
                                          default: "0.00",
                                          type: "number",
                                          min: "0",
                                          max: "1",
                                          step: "0.01",
                                          "single-line": "",
                                          "hide-details": "",
                                          solo: "",
                                          flat: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.matchRatio,
                                          callback: function ($$v) {
                                            _vm.matchRatio = $$v
                                          },
                                          expression: "matchRatio",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "input-div" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "input-label",
                                          attrs: {
                                            title:
                                              "Toggle between per-column LDDT and 3Di score matrix-based colorschemes",
                                          },
                                        },
                                        [_vm._v("Colours")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            dense: "",
                                            mandatory: "",
                                            color: "primary",
                                          },
                                          model: {
                                            value: _vm.colorScheme,
                                            callback: function ($$v) {
                                              _vm.colorScheme = $$v
                                            },
                                            expression: "colorScheme",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                "x-small": "",
                                                value: "lddt",
                                              },
                                            },
                                            [_vm._v("LDDT")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: { width: "40px" },
                                              attrs: {
                                                "x-small": "",
                                                value: "3di",
                                              },
                                            },
                                            [_vm._v("3Di")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                    width: "fit-content",
                                    height: "80px",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "toggle-button",
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        title: "Toggle MSA viewing options",
                                      },
                                      on: { click: _vm.toggleSettingsPanel },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(_vm.settingsBtnIcon)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "minimap-col" },
                        _vm._l(_vm.cssGradients, function (block, i) {
                          return _c(
                            "div",
                            {
                              key: "col-" + i,
                              staticClass: "gradient-block-col",
                              style: _vm.minimapBlock(i),
                              on: {
                                click: function ($event) {
                                  return _vm.handleMapBlockClick(i)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "gradient-block" },
                                _vm._l(block, function (gradient, j) {
                                  return _c("div", {
                                    key: "gradient-" + j,
                                    staticClass: "gradient-row",
                                    style: { "background-image": gradient },
                                  })
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { "pa-2": "" } },
            [
              _c("MSAView", {
                ref: "msaView",
                attrs: {
                  entries: _vm.msaViewEntries,
                  scores: _vm.msaViewScores,
                  alnLen: _vm.alnLen,
                  alphabet: _vm.alphabet,
                  colorScheme: _vm.colorScheme,
                  selectedStructures: _vm.structureViewerSelection,
                  referenceStructure: _vm.structureViewerReference,
                  matchRatio: parseFloat(_vm.matchRatio),
                  mask: _vm.mask,
                  highlightColumn: _vm.selectedColumn,
                },
                on: {
                  cssGradients: _vm.handleCSSGradient,
                  lineLen: _vm.handleLineLen,
                  newStructureSelection: _vm.handleNewStructureViewerSelection,
                  newStructureReference: _vm.handleNewStructureViewerReference,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }